import React, { useState } from 'react'
import {
  Button,
  Card,
  Grid,
  IconButton,
  CardHeader,
  Typography,
  CardContent,
  Divider,
  TextField,
  Tooltip,
} from 'components'
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { setStep, setCouponCode } from 'store/modules/new-purchase'
import useCart from '../utils/useCart'
import formatMoney from '../utils/formatMoney'
import { getCoupon, removeCoupon } from 'store/modules/new-purchase'

import LeafLogo from 'views/dashboard/components/icons/LeafLogo'
import EditIcon from '@mui/icons-material/Edit'
import ClearIcon from '@mui/icons-material/Clear'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import useGTM from 'utils/hooks/useGTM'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles({
  card: {
    padding: 20,
  },
  cardHeader: {
    fontWeight: '300',
  },
  itemHeader: {
    fontWeight: '500',
  },
  promoInput: {
    '& .MuiInputLabel-root': {
      fontSize: '0.875rem',
    },
    '& .MuiInputLabel-shrink': {
      fontSize: '1rem !important',
    },
  },
  avatar: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '40px',
  },
})

const DISCLAIMER = {
  month:
    '*Subscription starts 30 days after purchase or upon certification at training completion, whichever comes first.',
}

function Cart({ disabled, hideEdit, hasAccessory, isConfirmPage, isPurchaseComplete, next, step }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    subscriptions,
    chargedProducts,
    subtotalCharge,
    totalCharged,
    discountItems,
    selectedAddOnsArray,
    clientLicensesArray,
    promoDetails,
    tax,
  } = useCart()
  const [loading, setLoading] = useState(false)
  const { addPromoCode } = useGTM()
  const [searchParams, setSearchParams] = useSearchParams()
  const { enqueueSnackbar } = useSnackbar()

  const {
    redirectUrl,
    shipstationRates: allShipstationRates = [],
    selectedServiceCode = '',
    onboardingProducts,
    couponCode,
    couponError,
    appliedPromoCode,
    taxError,
  } = useSelector((state) => state.newPurchase.data)
  const { pending } = useSelector((state) => state.creditCard)
  // shipping
  const shipstationRates = allShipstationRates.filter(
    ({ serviceCode }) => selectedServiceCode && serviceCode === selectedServiceCode
  )

  const subscriptionsWithDiscount = subscriptions.map((subscription) => {
    const subscriptionDiscount = discountItems.find(
      (discount) =>
        discount.shouldApplyToSubscription && discount.requiredSkus.includes(subscription.sku)
    )

    return {
      ...subscription,
      discountName: subscriptionDiscount?.discountName,
      discountAmount: subscriptionDiscount?.discountAmount,
    }
  })

  const onClick = () => {
    if (hasAccessory) {
      navigate(redirectUrl)
    } else if (redirectUrl) {
      window.location.replace(redirectUrl)
    } else {
      dispatch(setStep(0))
    }
  }

  // visually subtract discount
  const onPromoCodeInputChange = async (event) => {
    await dispatch(setCouponCode(event.target.value))
    await setLoading(false)
  }

  const applyPromoCode = async (event) => {
    const cartProducts = [
      ...onboardingProducts.map((item) => ({ sku: item, quantity: 1 })),
      ...selectedAddOnsArray,
    ]
    await setLoading(true)
    await dispatch(
      getCoupon({
        promoCode: couponCode,
        cartProducts,
        callGTM: () => addPromoCode({ coupon: couponCode }),
        callback: () => enqueueSnackbar('promo code applied', { variant: 'success' }),
      })
    )
    await setLoading(false)
  }

  const handleRemoveCouponFromCart = async () => {
    const preservedCouponText = couponCode
    await setLoading(true)
    await dispatch(removeCoupon())
    await setLoading(false)
    await dispatch(setCouponCode(preservedCouponText))
  }

  const DiscountItem = ({ isRemovable = false, handleRemove, discountAmount, label }) => {
    return (
      <Typography
        variant="body2"
        display="block"
        gutterBottom
        align="right"
        className="font-medium text-link"
      >
        {isRemovable && (
          <IconButton aria-label="clear" onClick={handleRemove} size="small">
            <ClearIcon />
          </IconButton>
        )}
        {label}
        {formatMoney(discountAmount)}
      </Typography>
    )
  }

  const removeLineItem = (removedSku) => () => {
    const skus = searchParams.get('skus')
    if (skus) {
      const newSkus = skus
        ?.split(',')
        ?.filter((sku) => sku !== removedSku)
        ?.join(',')

      setSearchParams({ skus: newSkus })
      next(0)
    }
  }

  const RemoveLineItemText = ({ skuToRemove }) => (
    <Typography variant="body2">
      <Tooltip title={'Remove this item from the cart'} placement="left" arrow>
        <span>
          <Button onClick={removeLineItem(skuToRemove)} className="text-link">
            <Typography variant="caption">Remove</Typography>
          </Button>
        </span>
      </Tooltip>
    </Typography>
  )

  const DiscountedSubscriptionPriceText = ({ price, frequency, discount }) => {
    return (
      <span>
        {!discount && `${formatMoney(price)}/${frequency}`}
        {discount && (
          <span>
            <span
              style={{
                color: 'red',
                textDecoration: 'line-through',
                whiteSpace: 'nowrap',
              }}
            >
              {formatMoney(price)}{' '}
            </span>
            <span>
              {formatMoney(price - discount)}/{frequency}
            </span>
          </span>
        )}
      </span>
    )
  }

  return (
    <Grid container direction="column">
      {isConfirmPage && (
        <Card className={`mb-5 ${classes.card} `} elevation={0}>
          <Grid container justifyContent="space-between">
            <Grid item className="pl-4 my-1" xs>
              <TextField
                value={couponCode}
                error={!!couponError}
                helperText={couponError ? couponError : ''}
                id="enter-coupon"
                label="Have a promo code?"
                variant="outlined"
                className={`pr-3 w-full ${classes.promoInput}`}
                textAlign="center"
                size="small"
                onChange={onPromoCodeInputChange}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    applyPromoCode(e)
                  }
                }}
                disabled={loading || pending}
              />
            </Grid>
            <Grid item className="mt-3" xs={3} textAlign="center">
              <Button
                className="py-2"
                color="primary"
                variant="contained"
                disableElevation
                onClick={applyPromoCode}
                disabled={loading || !(couponCode && couponCode.length) || pending}
                data-test="apply-promocode-button"
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </Card>
      )}
      <Card className={classes.card} elevation={0}>
        <CardHeader
          title={`Your Purchase ${!hideEdit ? 'Selection' : ''}`}
          classes={{ title: classes.cardHeader }}
          titleTypographyProps={{ variant: 'h5' }}
        />

        {chargedProducts.map(({ price, cartTitle, description }, i) => (
          <CardHeader
            key={`chargedProducts${i}`}
            avatar={<LeafLogo />}
            title={`${cartTitle} ${formatMoney(price)}`}
            subheader={description || '(One-time payment)'}
            classes={{ title: classes.itemHeader, avatar: classes.avatar }}
          />
        ))}

        {subscriptionsWithDiscount
          .filter(({ immediateChargePrice }) => immediateChargePrice > 0)
          .map(({ price, frequency, description, cartTitle, discountAmount, discountName }, i) => (
            <CardHeader
              key={`subscriptions${i}`}
              avatar={<LeafLogo />}
              title={
                <span>
                  {cartTitle} {discountName ? `(${discountName}) ` : ''}
                  <DiscountedSubscriptionPriceText
                    price={price}
                    frequency={frequency}
                    discount={discountAmount}
                  />
                </span>
              }
              subheader={`(${description})`}
              classes={{ title: classes.itemHeader, avatar: classes.avatar }}
            />
          ))}
        {// if promo exists, it goes first
        promoDetails?.map(({ cartTitle, price, description, isRemovable, skuToRemove }, i) => (
          <CardHeader
            key={`chargedProducts${i}`}
            avatar={
              <>
                <LeafLogo />
                {isRemovable && !isPurchaseComplete && (
                  <RemoveLineItemText skuToRemove={skuToRemove} />
                )}
              </>
            }
            title={`${cartTitle} ${price ? formatMoney(price) : ''}`}
            subheader={description || '(One-time payment)'}
            classes={{
              title: classes.itemHeader,
              avatar: classes.avatar,
            }}
          />
        ))}

        {selectedAddOnsArray.map(({ name, price, quantity }, index) => (
          <CardHeader
            key={`addon${index}`}
            avatar={<LeafLogo color="primary" />}
            title={`${quantity > 1 ? quantity + ' x ' : ''} ${name} ${formatMoney(
              price * quantity
            )}`}
            subheader={'Add-on item'}
            classes={{ title: classes.itemHeader, avatar: classes.avatar }}
            data-test="products-total"
          />
        ))}

        {clientLicensesArray?.map(({ name, price, quantity }, index) => (
          <CardHeader
            key={`clientLicense${index}`}
            avatar={<LeafLogo color="primary" />}
            title={`${quantity > 1 ? quantity + ' x ' : ''} ${name} ${formatMoney(
              price * quantity
            )}`}
            subheader={''}
            classes={{ title: classes.itemHeader, avatar: classes.avatar }}
          />
        ))}

        {shipstationRates.map(({ serviceName, shipmentCost, otherCost }, index) => (
          <CardHeader
            key={`shipping${index}`}
            avatar={<LocalShippingIcon color="primary" />}
            title={`${serviceName} ${formatMoney(shipmentCost + otherCost)}`}
            subheader={'Shipping'}
            classes={{ title: classes.itemHeader, avatar: classes.avatar }}
          />
        ))}
        <>
          <Divider />
          <Typography
            variant="body2"
            display="block"
            gutterBottom
            className="pt-5 font-medium"
            align="right"
          >
            Subtotal: {formatMoney(subtotalCharge)}
          </Typography>

          <Typography
            variant="body2"
            display="block"
            gutterBottom
            className="font-medium"
            align="right"
          >
            Tax: {formatMoney(tax)}
          </Typography>
          {// do not show tax when we get an invalid postal code
          taxError && (
            <Typography
              variant="body2"
              display="block"
              gutterBottom
              className="font-medium text-link"
              align="right"
            >
              ({taxError})
            </Typography>
          )}
          {// the `?` is to account for discount skus that are no longer active
          discountItems
            ?.filter((discountItem) => !discountItem.shouldApplyToSubscription)
            .map((discountItem, index) => (
              <DiscountItem
                isRemovable={discountItem.isCouponDiscount && !isPurchaseComplete}
                handleRemove={handleRemoveCouponFromCart}
                label={
                  discountItem.isCouponDiscount
                    ? `Promo Code (${appliedPromoCode.toUpperCase()}): -`
                    : `${discountItem.discountName}: -`
                }
                discountAmount={discountItem.discountAmount}
                key={`discountItem-${index}`}
              />
            ))}
        </>
        <Typography
          variant="h5"
          display="block"
          gutterBottom
          className="pt-2 pb-5 text-green"
          align="right"
        >
          Total: {formatMoney(totalCharged)}
        </Typography>
        <Divider />

        {subscriptionsWithDiscount
          .filter(({ immediateChargePrice }) => immediateChargePrice === 0)
          .map(({ price, frequency, description, sku, discountAmount, discountName }, i) => (
            <CardHeader
              avatar={<LeafLogo />}
              title={
                <span>
                  {sku.includes('focus') ? 'ILS' : sku.includes('ssp') ? 'SSP' : ''} Subscription{' '}
                  {discountName ? `(${discountName}) ` : ''}
                  <DiscountedSubscriptionPriceText
                    price={price}
                    frequency={frequency}
                    discount={discountAmount}
                  />{' '}
                  + tax
                </span>
              }
              subheader={`(${description})`}
              classes={{ title: classes.itemHeader, avatar: classes.avatar }}
              key={`subscriptions${i}`}
            />
          ))}

        <CardContent>
          {subscriptionsWithDiscount.map(({ frequency, showDisclaimer }, i) => (
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              className="pb-5"
              key={`disclaimer${i}`}
            >
              {showDisclaimer && DISCLAIMER[frequency]}
            </Typography>
          ))}

          {!hideEdit && (
            <Grid container direction="row-reverse">
              <Grid item>
                <Button
                  onClick={onClick}
                  disabled={disabled}
                  disableElevation
                  startIcon={<EditIcon />}
                  color="inherit"
                >
                  Change
                </Button>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  )
}

export default Cart
